import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';

const Categories = () => {
    const query = useStaticQuery(graphql`
        query {
            allStrapiCategory {
                edges {
                    node {
                        id
                        slug
                        name
                    }
                }
            }
        }
    `);
    const categories = query.allStrapiCategory.edges;

    return (
        <ul className='uk-subnav uk-subnav-divider uk-flex-center uk-width-1-1'>
            {categories.map((category) => (
                <li key={category.node.id} className='uk-active'>
                    <Link
                        to={`/blog/category/` + category.node.slug}
                        activeClassName='uk-active'
                    >
                        {category.node.name}
                    </Link>
                </li>
            ))}
        </ul>
    );
};

export default Categories;
