import React from 'react';
import Categories from '../components/Categories';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';

const Blog = ({ data, pageContext }) => {
    const posts = data.allStrapiPost.edges;

    const { currentPage, NumberPages } = pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === NumberPages
    const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    return (
        <>
            <>
                <Categories />
                <ul>
                    {posts.map((post) => (
                        <li key={post.node.id}>
                            <Link
                                to={
                                    post.node.categories[0].slug +
                                    '/' +
                                    post.node.slug
                                }
                            >
                                {post.node.title}
                            </Link>
                        </li>
                    ))}
                </ul>

                <ul className="uk-pagination uk-margin">
                    {!isFirst && (
                        <li><Link to={prevPage} rel="prev">
                        <span uk-pagination-previous></span>
                        </Link></li>
                    )}
                    {Array.from({ length: NumberPages }, (_, i) => (
                        <li><Link key={`pagination-number${i + 1}`} to={`/blog/${i === 0 ? "" : i + 1}`}>
                        {i + 1}
                        </Link></li>
                    ))}

                    {!isLast && (
                        <li><Link to={nextPage} rel="next">
                        <span uk-pagination-next></span>
                        </Link></li>
                    )}
                </ul>
            </>
        </>
    );
};

export default Blog;

export const pageQuery = graphql`
    query BlogIndex($skip: Int!, $limit: Int!) {
        allStrapiPost(
      sort: { fields: [publishedAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
            edges {
                node {
                    id
                    slug
                    title
                    categories {
                        slug
                    }
                }
            }
        }
    }
`;
